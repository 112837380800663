:local {
  .label {
    color: #727272;
    cursor: pointer;
	  display: block;
	  font-size: 0.8em;
	  font-weight: 600;
	  margin-bottom: 0.6em;
	  text-transform: uppercase;
	  letter-spacing: -0.132px;
    margin-bottom: 1.32em;

    &.isFloating {
      background: #FFF;
      display: block;
      font-size: 0.8125em;
      font-weight: 400;
      padding: 0 0.38461538em;
      transition: all 0.25s ease-out;
      text-align: left;
      position: absolute;
      top: -0.66em;
      left: 0.66em;
      z-index: 1;
    }
  }
}
