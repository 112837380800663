@import "src/themes/__variables.scss";

:local {
    .container {
        border-bottom: 2px solid var(--background-color);
    }

    .mainLogo {
		padding: 2.32em 2em 0 2em;
        background-color: var(--background-color);
        font-family: Oxygen, sans-serif;
    }

    .info {
        padding: 2em 2em 2.8em 2em;
        background-color: var(--background-color);
        text-align: center;
		font-family: Oxygen, sans-serif;

        h1 {
            color: var(--title-color);
            font-size: 1.72em;
            color: var(--title-color);
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 1em;
        }

        p {
            color: #8F8F8F;
            line-height: 1.72;
            max-width: 650px;
            margin: 0 auto;
            font-size: 14px;
        }
    }

    .itemsArea {
        max-width: 500px;
        margin: 0 auto;
    }

    .body {
        background-color: var(--body-background-color);
        text-align: center;
        padding: 3.75em 1.15em 4.375em 1.15em;
		font-family: Oxygen, sans-serif;
        h2 {
            color: var(--title-color);
			font-weight: lighter;
			margin: 0 0 2.5em 0;
        }
    }
}
