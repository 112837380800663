:local {
  .dropdown {
    display: block;
    transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    position: relative;
    width: 100%;
    font-family: 'Oxygen', sans-serif;
    font-weight: normal;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
}

:global {
  .sh-select__container {
    .sh-select__control {
      background: var(--background-color);
      font-size: 1em;
      border-width: 1px;
      border-color: transparent;
      box-shadow: none;
      transition: none;
      z-index: 1;

      &:hover {
        border-color: transparent;
      }

      &--is-focused {
        border-color: transparent;
      }

      &--menu-is-open {
        border-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &--menu-is-open {
          .sh-select__dropdown-indicator {
            transform: rotate(180deg);
            polygon {
              stroke: #000; // IE11 fallback
              stroke: var(--color-selected) !important;
              fill: var(--color-selected) !important;
            }
          }

          .sh-select__indicator-separator {
            background-color: var(--color-selected) !important;
          }

          .sh-select__container,
          .sh-select__placeholder,
          .sh-select__container,
          .sh-select__single-value {
            color: var(--color-selected) !important;
          }
        }
      }

      .sh-select__dropdown-indicator {
        polygon {
          stroke: #000; // IE11 fallback
          stroke: var(--text-color);
          fill: var(--text-color);
        }
    }

    .sh-select__value-container {
      padding: .8125em;
    }

    .sh-select__container {
      border: none;
    }

    .sh-select__placeholder,
    .sh-select__single-value {
      color: var(--text-color);
      margin-left: 0;
      margin-right: 0;
      overflow: visible;
    }

    .sh-select__menu {
      width: calc(100% + 2px);
      box-shadow: none;
      background-color: var(--background-color);
      border: 1px solid #000; // IE11 fallback
      border: 1px solid transparent;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      left: -1px;
      margin-top: -2px;
      z-index: 999;
      border: 1px solid var(--border-color);
      border-top-color: transparent;
    }

    .sh-select__menu-list {
      border: 1px solid transparent;
      padding-top: 10/16+em;
      margin: 0 15/16+em;
    }

    .sh-select__option-container {
      position: relative;
      .sh-select__vehicle-voucher-value {
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid transparent;
        border-radius: 4px;
        font-size: 12/16+em;
        padding: 4px 8px;
        color: var(--color-selected);
      }
    }

    .sh-select__option {
      width: auto;
      color: #676767;
      border-radius: 2px;
      padding: 10/16+em 15/16+em;

      &--is-focused {
        background-color: var(--focus-item-color);
      }

      &--is-selected {
        background-color: #000; // IE11 fallback
        background-color: #1E1E1F;
        color: white;
      }
    }

    .sh-select__indicator-separator {
      margin-top: 6/16+em;
      margin-bottom: 6/16+em;
      background-color: #878787;
    }

    .sh-select__dropdown-indicator {
      padding: 0 12/16+em;
      display: block;
    }
  }

  .sh-select__value-container--has-value > div {
    color: var(--focus-text-color) !important;
  }
}
